.trictextaread{
    background-color: #010e35;
    color: white;
}

.truckcontinrprid{
    display: grid;
    grid-template-columns: repeat( 2, 1fr);
}

.truckikagesiosde img{
    width:100%;
    height: 80vh;
}

.trictextaread{
    display: flex;
    justify-content: center;
    align-items: center;
}

.testallwrapper{
    padding: 2rem;
    text-align: center;
    background-color: white;
    color: black;
}

.testallwrapper h3{
    font-weight: bolder;
    color: #010e35;
    padding: 1rem 0;
}

.testallwrapper h2{
    color: #010e35;
    padding: 1rem 0;
}

@media screen and (max-width:800px){
    .truckcontinrprid{
        display: grid;
        grid-template-columns: repeat( 1, 1fr);
    }
	
  }

.adminheadercontainer{
    
    color: black;
    box-shadow: 2px 6px 5px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 2px 6px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 2px 6px 5px 0px rgba(0,0,0,0.75);
}

.admincontent{
    padding: 1rem;
}

.adminas{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.shipmentconatiner{
    overflow: auto;
}

.table>:not(caption){
    
    margin-top: 1rem;
}

.headertracker h1{
    font-size: 1.4rem;
    padding: 1rem;
}

.headertracker button{
    padding: 0.32rem 1rem;
    background-color: #111111;
    color: white;
    margin: 1.5rem 0;
    border: none;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.logoside > a img{
 height: 10vh;
}

.conentsode{
    text-align: end;
}

.conentsode button{
    background-color: black;
    color: white;
    padding: 0.32rem 1rem;
    border: none;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.dashboardcontent{
    padding: 2rem;
}

.dashboarditem{
     display: grid;
     grid-template-columns: repeat(3, 1fr);
     gap: 10px;
}

.conatnere{
   
    box-shadow: 1px -1px 5px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 1px -1px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 1px -1px 5px 0px rgba(0,0,0,0.75);
}

.conatereheader{
    text-align: center;
    text-transform: capitalize;
    font-size: 1.5rem;
    background-color: black;
    color: white;
    padding: 1rem;
}

.coneterdaterr{
    padding: 1rem;
    text-align: center;
}

.coneterdaterr button{
    background-color: black;
    border: none;
    width: 100%;
    color: white;
    padding: 00.32rem;
}

.textalejdjd{
    text-transform: capitalize;
    text-align: center;
    padding: 2rem;
    font-weight: bolder;

}

.shipmentconatiner{
    padding: 1rem;
}

.creatnewshipment button{
    background-color: #111111;
    color:#fff;
    border: none;
    padding: 0.34rem 1rem;
    margin-bottom: 1rem;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.workid{
    font-size: 1.5rem;
    color: #ed1864;
    text-transform: capitalize;

}



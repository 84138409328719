.servi{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.servicecontent{
    background-color: #001a6984;
    color: white;
    padding: 2rem;
}

.serviceheader{
    font-size: 3rem;
    text-align: start;
    font-weight: bolder;
    text-transform: capitalize;
    color: white;
}

.gridcontainer{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    
}

.servicegrid{
    padding: 1rem;
    box-shadow: 1px 1px 6px -1px rgba(17, 12, 83, 0.75);
    -webkit-box-shadow: 1px 1px 6px -1px rgba(26, 13, 54, 0.75);
    -moz-box-shadow: 1px 1px 6px -1px rgba(11, 8, 45, 0.75);
}

.iconsectionservice{
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;
    padding: 1rem;
    
}

.iconservice{
     font-size: 1rem;
     font-weight: bolder;
     color: white;
     margin-right: 1rem ;
}

.icontittle{
     font-size: 1rem;
     font-weight: bolder;
}


@media screen and (max-width:920px){
    .gridcontainer{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        
    }
    
  
  }


  @media screen and (max-width:620px){
    .gridcontainer{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
        
    }

    
.serviceheader{
    font-size: 2rem;
    padding: 2rem;
}
    
  
  }

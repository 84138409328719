.footeitem{

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    justify-content: center;
    padding: 7rem 2rem;
}

.imageside{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0 2rem ;
}

.icons{
    margin: 1rem;
    font-size: 3rem;
    color: #010e35;
}

.messageside h1{
    font-size: 1.4rem;
    color: #010e35;
    width: 100%;
}

.feetnoot{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background-color: #111111;
    color: #fff;
}

.sjehgdy{
    margin: 1rem;
}

@media screen and (max-width:820px){


    .footeitem{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
        justify-content: center;
        padding: 2rem 1rem;
    } 

    .messageside p{
        width: 100%;
        
    }

   
  
  }


  @media screen and (max-width:420px){
    .messageside {
        padding: 1rem 3rem; 
    }

    .imageside{
        padding: 0 1rem;
    }
    .icons{
        font-size: 2rem;
    }
  
  }

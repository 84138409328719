.deliverycontent{
    padding: 3rem 1rem;


}

.deliveryconten11 img{
    height: 100vh;
    width: 100%;
}

.deliverywrapper{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.deliveryconten img{
    width: 100%;
}

.deliverycontentheader{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.deliverycontentheader1 h1{
  
    color: white;
    font-size: 3rem;
    
}

.deliverycontentheader1 p{
    line-height: 2rem;
    color: white;
}

/* .deliveryconten button{
    padding: 0.34rem 1rem ;
   
    color: white;
    border: none;
    border-radius: 5px;
    margin: 1.5rem 0;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
} */


@media screen and (max-width:930px){

    .deliverycontentheader1 h1{
  
        color: white;
        font-size: 2rem;
        
    }
    
    .deliverycontentheader1 p{
        color: black;
    }
  }


@media screen and (max-width:730px){
    .deliverycontentheader{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
    }

    
.deliverywrapper{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
}


.deliverycontentheader1 h1{
  
    color: white;
    font-size: 1.7rem;
    
}


.deliveryconten11 img{
    height: 54vh;
    width: 100%;
}


  }
.headersection{
    text-align: center;
    padding: 1rem;
    width: 50%;
    margin: auto auto;
}

.headersection h1{
   text-transform: capitalize;
   color: #001b69;
   font-size: 2.4rem;
   margin-top: 2rem;
   font-weight: bolder

}


.content{
    width: 90%;
    margin: auto auto ;
}

.grid {
	position: relative;
	margin: 0 auto;
	padding: 1em 0 4em;
	list-style: none;
	text-align: center;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

}

/* Common style */
.grid figure {
	position: relative;
	overflow: hidden;
	margin: 10px 1%;

	background: #011b25b4;
	text-align: center;
	cursor: pointer;
}

.grid figure img {
	position: relative;
	display: block;
	min-height: 100%;
	max-width: 100%;
	opacity: 0.8;
}

.grid figure figcaption {
	padding: 2em;
	color: white;
	text-transform: uppercase;
	font-size: 1.25em;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
	pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption > a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.grid figure figcaption > a {
	z-index: 1000;
	text-indent: 200%;
	white-space: nowrap;
	font-size: 0;
	opacity: 0;
}

.grid figure h2 {
	word-spacing: -0.15em;
	font-weight: 300;
}

.grid figure h2 span {
	font-weight: 800;
}

.grid figure h2,
.grid figure p {
	margin: 0;
}

.grid figure p {
	letter-spacing: 1px;
	font-size: 68.5%;
}



figure.effect-lily img {
	max-width: none;
	width: -webkit-calc(100% + 50px);
	width: calc(100% + 50px);
	opacity: 0.7;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: translate3d(-40px,0, 0);
	transform: translate3d(-40px,0,0);
}

figure.effect-lily figcaption {
	text-align: left;
}

figure.effect-lily figcaption > div {
	position: absolute;
	bottom: 10px;
	left: 0;
	padding: 2em;
	width: 100%;
	height: 50%;

}

figure.effect-lily h2,
figure.effect-lily p {
	-webkit-transform: translate3d(0,40px,0);
	transform: translate3d(0,40px,0);
    color:white;
    font-weight: bolder;
}

figure.effect-lily h2 {
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
}

figure.effect-lily p {
	color: white;
	opacity: 1;
	-webkit-transition: opacity 0.2s, -webkit-transform 0.35s;
	transition: opacity 0.2s, transform 0.35s;
    text-transform: capitalize;
}

figure.effect-lily:hover img,
figure.effect-lily:hover p {
	opacity: 0.9;
}

figure.effect-lily:hover img,
figure.effect-lily:hover h2,
figure.effect-lily:hover p {
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

figure.effect-lily:hover p {
	-webkit-transition-delay: 0.05s;
	transition-delay: 0.05s;
	-webkit-transition-duration: 0.35s;
	transition-duration: 0.35s;
}


@media screen and (max-width:930px){
	.headersection{
		text-align: center;
		padding: 1rem;
		width: 80%;
		margin: auto auto;
	}
	.headersection h1{
		text-transform: capitalize;
		color: #001b69;
		font-size: 1.4rem;
		margin-top: 2rem;
		font-weight: bolder
	 
	 }

	 
.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

}
	 
	
  }

  @media screen and (max-width:630px){
	.headersection{
		text-align: center;
		padding: 1rem;
		width: 90%;
		margin: auto auto;
	}
	.headersection h1{
		text-transform: capitalize;
		color: #001b69;
		font-size: 1.4rem;
		margin-top: 2rem;
		font-weight: bolder
	 
	 }


	 .grid {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
	
	}

	.alnewjsjs h2{
		font-size: 1.3rem !important;
	}
	 
	figure.effect-lily figcaption > div{
		position: absolute;
    bottom: 10px;
    left: 0;
    padding: 2em;
    width: 100%;
    height: 40%;
	}

	.figure.effect-lily img{
		height: 45vh;
	}
	
  }
.dashboardmenueICon{
    font-size: 2rem;
    padding: 1rem;
    background-color: #111111ce;
    color: white;
}
.logjeade{
  text-transform: capitalize;
  font-weight: 700;
  font-size: 1.3rem;
}

.alldfghjkl{
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #ed1864;
  padding: 2rem;
  font-size: 2.5rem;
}
.convascontainer{
    background-color: #111111 !important;
    color: white !important;
}
.offcanvas-start{
    width: 20% !important;
    background-color: #000;
}

.paymenthistory{
  text-align: center;
  padding: 2rem;
  font-weight: 700;
  color: #ed1864;
  font-size: 2.4rem;
}
.requestButton{
  border-radius: 5px;
  color: white;
}

.titleDashboard{
    color: white;
    background-color: #ed1864;
}

.requestcontainer{
  width: 100%;
  background-color: #111111;
  color: white;
}
.statisticsitem{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 2rem;
}
.itemstatistic:hover{
  background-color: #ed1864;
  color: white;
}
.amountside{
  text-align: center;
  padding: 1rem;
  font-size: 2rem;
  font-weight: bolder;
}
.titileside{
  text-align: center;
}

.titileside button{
  background-color: #444444;
  color: white;
  padding: 0.23rem 1rem;
  border: none;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  margin: 0 1rem 1rem 0;
}

.itemstatistic{
  display: flex;
  justify-content: center;
  border: 1px solid #ed1864;
 
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.messageArea p{
    background-color: #8292c921;
    padding: 1.1rem;
    border-radius: 10px;
  }
  
  .spandve{
    background-color: #e7d366;
    border-radius: 10px;
    padding: 1.1rem;
  }

  .filtersetsec {
    padding:  1rem 0;
  }
  .filtersetsec  button{
    margin-right: 0.5rem;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    background-color: #ed1864;
    color: white;
}

.filtersetsec  input{
 padding: 0.2rem 2rem;
  border-radius: 10px;
  border: 1px solid #ed1864;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

  .spandve span{
    font-weight: 700;
    color: #2f3b66;
    padding-right: 0.45rem;
  }
  .thiswor{
    padding: 0 2rem ;
    min-height: 100vh;
  }
  table {
      border-collapse: collapse;
      border-spacing: 0;
      border: 1px solid #ddd;
      width: 100%;
  
    }
  
    .thiswor a{
      text-decoration: none;
      color: white;
    }
    
  .btn input{
    padding: 0.35rem;
  border-radius: 5px;
  border: #2f3b66 solid 1px;
  }
  .detsssails{
    padding: 0.35rem;
    border-radius: 5px;
    border: #2f3b66 solid 1px;
  }
  .retrunS{
    text-align: center;
    padding: 1rem;
  }
  
  .retrunS a{
  text-decoration: none;
  color: white;
  }
  
  .retrunS button{
    padding: 0.25rem 1rem;
    border: none;
    background-color: #2f3b66;
    color: white;
    border-radius: 5px;
  }
    .thiswor button{
      border: none;
      padding: 0.24rem 1rem;
      font-weight: 300;
  
    }
    .details button{
      padding: 0.25rem  1rem;
      background-color: #2f3b66;
      color: white;
      margin: 0.24rem;
      border: none;
      border-radius: 5px;
    }
  .usrinpit{
   padding: 1rem 0 1rem 1rem;
  
   text-align: center;
   font-size: 1.3rem;
   text-transform: capitalize;
   font-weight: bold;
   color: white;
   
  }
  .btn button{
  padding:0.6rem;
  background-color: #2f3b66;
  border-radius: 10px;
  border: none;
  color: white;
  text-decoration: none;
  margin: 0.25rem ;
  }
  .btn button > a{
    text-decoration: none;
    color: white;
    }
  
  .headtable{
    width: 100%;
    margin:  auto auto;
  }
  
  .addexpende a{
  padding: 1rem;
  margin: 1rem;
    color: white;
    display: flex;
    align-items: center;
    background-color: #2f3b66;
    width: fit-content;
    border-radius: 10px;
    text-decoration: none;
  
  
  }
  
  
  .addexpende:hover a{
      color: #e7d366;
    }
  
  .details{
     text-transform: capitalize;
     color: red;
  }
    .adduser button{
      padding: 0.75rem 0.8rem;
      background-color: #2f3b66;
      border: none;
      color: white;
      border-radius: 5px;
  
    }
    .adduser:hover button{
   
      background-color: #e7d366
      
    }
  
    .usrinpit:focus input{
      border: none;
    }
    .usrinpit input{
      font-size: 0.65rem;
      padding: 0.5rem;
      color: black;
      width: 100%;
      border-radius: 10px;
      border: none;
      background-color:#2f3b6686;
    }
  
  
    th{
        background-color: #2f3b66;
        border: 1px solid white;
        color: white;
    }
    
    th, td {
      text-align: left;
      padding: 8px;
      border: 1px solid white;
    }
    
    tr:nth-child(even){background-color: #f2f2f2}
  
    .thiswor{
        overflow-x: auto;
        /* padding: 3rem 0; */
    }
  
    
    .thiswor img{
        height: 3rem;
        border-radius: 15px;
    }
  
  .textsss button{
      border: none;
      background-color: transparent;
  }
  
  .hshhs{
      text-align: center;
      font-size: 1rem;
      color: red;
  }
  
  .thiswor td >button{
    background-color: #2f3b66;
    color: white;
    border: none;
    padding: 0.45rem 1rem;
  }
  
  details{
    background-color: #2f3b66;
    color: white;
    border: none;
    padding: 0.45rem 1rem;
    margin: 0  1rem
  }


  .getusersecurityanswer{
    text-align: center;
    color: white;
  }

  .secirutconyent{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #111111;
  }

  .itemofsecurity span{
    color: #ed1864;
    font-weight: 700;
    text-transform: capitalize;
  }

  .itemofsecurity h6{
    margin-top: 1rem;
  }
  
  .itemofsecurity input{
    width: 100%;
    border: 1px solid #ed1864;
    margin: 0.5rem 0;
    text-align: center;
    border-radius: 5px;
    padding: 0.3rem 0;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}


  .itemofsecurity button{
  padding: 0.23rem 1rem;
  border: none;
  border-radius: 5px;
  background-color:#ed1864;
  color: white;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
 .thiswor td >button:hover{
    background-color: #e7d366;
  }
  
  .messageArea{
    border: #2f3b66 1px solid;
    color: #000;
    border-radius: 10px;
    max-width: 80%;
    padding: 1.5rem !important;
    margin: 1.5rem  !important;
    box-shadow: -4px 0px 5px -3px rgba(0,0,0,0.66);
  -webkit-box-shadow: -4px 0px 5px -3px rgba(0,0,0,0.66);
  -moz-box-shadow: -4px 0px 5px -3px rgba(0,0,0,0.66);
  }
  
  .messageArea li{
    list-style-type: none;
    text-decoration: none;
  }
  
  .messageArea button{
    padding: 0.24rem 1rem;
    background-color: #2f3b66;
    color: white;
    border: none;
    border-radius: 5px;
  }
  
  
    @media only screen and (max-width: 900px) {
      .thiswor{
         font-size: 0.65rem;
      }
    }
  
  
    @media only screen and (max-width: 700px) {
     
    .headtable{
      width: 100%;
      margin:  auto auto;
    }
    tr:nth-child(even){
      background-color: #f2f2f2;
    }
    }

    @media only screen and (max-width: 450px) {
    .filtersetsec{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    }

    .filtersetsec button{
     width: 100%;
     margin: 0.2rem;

    }

    .filtersetsec input{
      width: 100%;
      margin: 0.2rem;    
 }
}
  
    
.imagecontent{
    text-align: center;
    padding: 1rem;
}

.mageside img{
    object-fit: cover;
    cursor: pointer;
    width: 100%;
    height: fit-content;
    object-fit: cover;
    border-radius: 20px;
 
}

.editimage button{
    border: none;
    padding: 0.46rem  1rem;
    border-radius: 9px;
    background-color: #ed1864;
    color: white;
    margin-top: 0.4rem;
}

.previewImage img{
    height: 25vh;
    object-fit: cover;
    border-radius: 10px;

}

.previewImage{
    text-align: center;
    margin: 1rem;
}
.menuesidecanvas{
    color: white;
    
}

.menuesidecanvas{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 50vh;
}

.Request{
    display: flex;
    align-items: center;
    padding: 1rem 0;
    border-bottom: #ed1864 1px solid;
    cursor: pointer;
}

.Request:hover{
    color: #ed1864;
}
.offcanvas-backdrop.show{
    opacity: 0 !important;
}

.offcanvas-body{
  background-color: #000;
}

.requestheader{
    text-align: center;
    color: black;
    text-transform: capitalize;
    padding: 1rem;
    font-weight: bolder;
    font-size: 1.7rem;
}

@media screen and (max-width: 400px) {
    .offcanvas-start{
       width: 85% !important;
    }
    .editimage button{
        border: none;
        padding: 0.24rem 1rem !important;
      
    }
    

}

@media screen and (max-width: 700px) {
    .offcanvas-start{
       width: 80% !important;
    }

    .statisticsitem{
      display: grid;
      grid-template-columns: repeat(1, 1fr);
         }

         .mageside img{
          object-fit: cover;
          cursor: pointer;
          width: 100%;
          height: 30vh;
          object-fit: cover;
          border-radius: 20px;
       
      }
      

}

@media screen and (max-width: 837px) {
    .offcanvas-start{
       width: 81% !important;
    }

}


@media screen and (max-width: 407px) {
  .paymenthistory{
    font-size: 1.6rem;
  }

}




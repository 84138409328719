
.shipmenmtcontener{
    background-color: rgba(0, 0, 0, 0.781);
    padding: 2rem 0;
    
}

.shipmentinformatio{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.shopmnerheader p{
    color: white;
    font-size: 0.9rem;
}

.shopmnerheader h3{
    font-size: 3rem;
    font-weight: bolder;
    color:#c91e1e;
    margin: 1rem 0;
}

.shopmnerheader{
    text-align: center;
    padding: 2rem;
    color: white;
}

.shopmenrcontner11{
    width: 60%;
    background-color: rgba(241, 236, 226, 0.712);
    margin:  auto auto;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.de{
    color: #c91e1e;
    padding-bottom: 1rem;
}

.detainsconta{
    padding-top: 2rem;
}

.detainsconta h2{
    color: #c91e1e;
    font-size: 1.3rem;
}

.contaitem{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    
}

.shpermwnroform {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0 2rem;
}

.shipmenthistry{
   
    background-color: #fff;
    overflow: auto;
    width: 60%;
    margin: 3rem auto 2rem auto;

    
}

.sdssline{
    opacity: 2.5rem !important;
}

.histortconetnt{
    font-size: 1.3rem !important;
    margin: 0.5rem 0 ;
}

.lastbidjdfbhf{
    border-bottom: 1px solid black !important;
}
.shiperinformaion h2{
    color: #c91e1e;
    font-size: 1.3rem;
}


.shipementdetails{
    padding: 2rem;
    
}

.hrodmd{
    color: black;
}

.shipmentstatus{
    text-align: center;
    padding: 1rem;
    background-color: black;
    color: white;
    font-weight: bolder;
    text-transform: uppercase;
}

.shipmentstatus h2{
    font-size: 1.3rem;
    text-transform: capitalize;
    color: #c91e1e;
}

.shiperinformaion p {
color: black;
}

.shipementdetails h1{
    font-size: 1.3rem;
   text-transform: capitalize;
    color: #c91e1e;

}

.conteneralshipment p{
    color: black;
}

.shipmenthistry h2{
    font-size: 1.3rem;
    text-transform: capitalize;
    margin-top: 1rem;
     
}


@media screen and (max-width:720px){


    .contaitem{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    text-align: center;
    
}

.shpermwnroform {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    text-align: center;
    
}

.shipmenthistry{
    margin: 0 0.3rem;
    background-color: #fff;
    overflow: auto;
    
}


.shopmnerheader h3{
    font-size: 1.5rem;

  
}


.shopmenrcontner11{
    width: 95%;
    margin:  auto auto;
}

.shipmenthistry{
  
    width: 95%;
    margin: 3rem auto 2rem auto;

    
}
  
  }
.onebesthea p, .thehs, .allrighte{
font-size: 1.5rem;
font-weight: lighter;
color: #ed1864;
}

.onebestcontainer{
    padding: 2rem;
}

.onebesthea h2{
    font-weight: bolder;
    padding-bottom: 0.5rem;
}

.onebestcontent{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-top: 1rem;
    gap: 20px;
    line-height: 2.5rem;

}
.onebesthea{
    padding: 0 1rem;
}
.onebestleft{
    padding: 1rem;
}
.onebestright{
    background-color: #010e35;
    color: white;
    padding: 1rem;
}

.onebestrightcontainer{
    line-height: 3rem;
}
@media screen and (max-width:720px){

    .onebestcontent{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    
    }

    .onebestcontainer{
        padding: 2rem;
    }
    
  
  }


